/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { withFormik } from 'formik';
import * as yup from 'yup';
import RegistrationForm from '../components/RegisterForm';
import Container from '../components/Container';
import Layout from '../components/LoginLayout';
import * as api from '../utils/api';
import { showNotification, SEVERITY, hideAllNotifications } from '../state/notifications';
import { locNavigate } from '../state/session';
import getLanguage from '../utils/getLanguage';
import * as analytics from '../utils/analytics';

const phoneNumberRegExp = /^\+?[0-9\s]{6,18}$/;
const vatNumberRegExp = /^[0-9]{7}-[0-9]{1}$/;

const registrationSchema = translate =>
  yup.object().shape({
    email: yup
      .string()
      .required(translate('register.message.requiredField'))
      .email(translate('register.message.invalidEmail')),
    password: yup
      .string()
      .min(6, translate('register.message.tooShort'))
      .required(translate('register.message.requiredField')),
    passwordAgain: yup
      .string()
      .required(translate('register.message.requiredField'))
      .oneOf([yup.ref('password')], translate('register.message.passwordMismatch')),
    companyName: yup.string().when('company', {
      is: true,
      then: yup.string().required(translate('register.message.requiredField')),
    }),
    vatNumber: yup.string().when('company', {
      is: true,
      then: yup.string().matches(vatNumberRegExp, translate('register.message.invalidVatNumber')),
    }),
    firstname: yup.string().required(translate('register.message.requiredField')),
    lastname: yup.string().required(translate('register.message.requiredField')),
    phoneNumber: yup
      .string()
      .required(translate('register.message.requiredField'))
      .matches(phoneNumberRegExp, translate('register.message.invalidPhoneNumber')),
    recaptcha: yup.string().required(),
  });

const RegistrationFormik = withFormik({
  mapPropsToValues: ({ user }) =>
    Object.assign({
      email: user.email || '',
      password: user.password || '',
      passwordAgain: user.passwordAgain || '',
      company: false,
      vatNumber: user.vatNumber || '',
      recaptcha: user.recaptcha || '',
      //selection: user.selection || 'personSelection'
    }),
  validationSchema: ({ translate }) => {
    return registrationSchema(translate);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },
  displayName: 'RegistrationInputForm',
})(RegistrationForm);

export default ({ pageContext }) => {
  analytics.usePageCategory('omat_sivut');
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const language = useSelector(state => getLanguage(state));
  const onSubmit = useCallback(
    async (user, { setSubmitting, setFieldError }) => {
      dispatch(hideAllNotifications());
      try {
        const response = await api.register({
          ...user,
          phoneNumber: user.phoneNumber.replace(/\s/g, ''),
          username: user.email,
          language,
          recaptcha: user.recaptcha,
        });
        console.log(response);
        localStorage.setItem('userId', response.userId);
        dispatch(showNotification('register.message.success', SEVERITY.INFO));
        setUser({});
        setTimeout(() => {
          dispatch(locNavigate('/register-success'));
        }, 3000);
      } catch (error) {
        if (error.code === 'UsernameExistsException') {
          setFieldError('email', translate('register.message.userExists'));
          // dispatch(locNavigate('/request-password-reset'));
        } else if (error.code === 'UsernameExistsException2') {
          // user not confirmed yet
          setFieldError(
            'email',
            translate('register.message.userExists') + '. ' + translate('register.message.resendValidationEmailSuccess')
          );
          // dispatch(locNavigate('/email-validate', '?' + new URLSearchParams({ email: user.email }).toString()));
        } else {
          dispatch(showNotification('register.message.registrationFailed'));
        }
      }
    },
    [dispatch, language, translate]
  );

  const formProps = { user, onSubmit, translate };

  return (
    <Layout title={translate('register.header')} paths={pageContext.paths} locale={pageContext.locale || 'en'}>
      <Styled.h1 sx={{ mt: [0, 3, 4] }}>{translate('register.header')}</Styled.h1>
      <Styled.p>{translate('register.text')}</Styled.p>
      <RegistrationFormik {...formProps} />
    </Layout>
  );
};
